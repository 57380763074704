import Swiper from 'swiper/bundle';

const listingThumb = new Swiper('.listing__content-slider .slide-thumb', {
    slidesPerView: 5,
    spaceBetween: 8,
    grabCursor: true,
    scrollbar: {
        el: '.listing__content-slider .swiper-scrollbar',
        draggable: true,
    },
    breakpoints: {
        600: {
            slidesPerView: 7,
        }
    }
});

const listingMain = new Swiper('.listing__content-slider .slide-main', {
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
    speed: 500,
    navigation: {
        nextEl: '.listing__content-slider .swiper-button-next',
        prevEl: '.listing__content-slider .swiper-button-prev',
    },
    thumbs: {
        swiper: listingThumb,
    },  
});