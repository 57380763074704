import * as ScrollMagic from 'scrollmagic';
import { Luminous, LuminousGallery } from 'luminous-lightbox';

const hamburger = document.getElementById('hamburger');
const masthead = document.getElementById('masthead')

document.addEventListener('DOMContentLoaded', function(){
    hamburger.addEventListener("click", () => {
        masthead.classList.toggle("js-active");
    });
});