import Swiper from 'swiper/bundle';

const listingsSliderOne = new Swiper('.one .swiper', {
	slidesPerView: 1,
	spaceBetween: 24,
	grabCursor: true,
	paginationClickable: true,
	navigation: {
		nextEl: '.one .swiper-button-next',
		prevEl: '.one .swiper-button-prev',
	},
	breakpoints: {
		599: {
			slidesPerView: 3,
		}
	},
});

const listingsSliderTwo = new Swiper('.two .swiper', {
	slidesPerView: 1,
	spaceBetween: 24,
	grabCursor: true,
	navigation: {
		nextEl: '.two .swiper-button-next',
		prevEl: '.two .swiper-button-prev',
	},
	breakpoints: {
		599: {
			slidesPerView: 3,
		}
	},
});